import DateUtils from "../lib/DateUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class SetKyufuNengetsu {

    static initComponent(parentElement: HTMLElement) {
        document.querySelectorAll<HTMLElement>("[data-action~=SetKyufuNengetsu]").forEach((element) => {
            new SetKyufuNengetsu(parentElement, element);
        });
    }

    private parentElement: HTMLElement;
    private element: Element;

    private constructor(parentElement: HTMLElement, element: HTMLElement) {
        this.parentElement = parentElement;
        this.element = element;

        this.element.addEventListener("change", event => this.handleChange(event));
    }

    private handleChange(event: Event) {

        let bumonCodeElement = ObjectUtils.require(this.parentElement.querySelector("#bumonCode"), HTMLInputElement);
        let kyufubiElement = ObjectUtils.require(this.parentElement.querySelector("#kyufubi"), HTMLInputElement);
        let kyufuNengetsuElement = ObjectUtils.require(this.parentElement.querySelector("#kyufuNengetsu"), HTMLInputElement);
        let seikyuNengetsuElement = ObjectUtils.require(this.parentElement.querySelector("#seikyuNengetsu"), HTMLInputElement);

        let bumonCode = bumonCodeElement.value;
        let kyufubi = kyufubiElement.value;

        let kyufubiDate = DateUtils.parse(kyufubi);

        if (bumonCode === "4" && !(kyufubiDate instanceof Error)) {
            kyufuNengetsuElement.value = kyufubi.substring(0, 7);

            let now = DateUtils.now();
            let firstDay;
            let shimebi = DateUtils.newInstance(now.getFullYear(), now.getMonth(), 11);

            if (now < shimebi) {
                firstDay = DateUtils.newInstance(now.getFullYear(), now.getMonth() - 1, 1);
            } else {
                firstDay = DateUtils.newInstance(now.getFullYear(), now.getMonth(), 1);
            }

            if (firstDay <= kyufubiDate) {
                seikyuNengetsuElement.value = kyufubi.substring(0, 7);
            } else {
                seikyuNengetsuElement.value = DateUtils.toISODateString(now);
            }

        } else {
            kyufuNengetsuElement.value = "";
            seikyuNengetsuElement.value = "";
        }


        kyufuNengetsuElement.dispatchEvent(new CustomEvent("change"));
    }
}

export default SetKyufuNengetsu;