import * as domutils from '../domutils/index';

export class ClearButton {
    element: Element;

    constructor(element: Element) {
        this.element = element;
        element.addEventListener("click", (event) => this.handleClick(event));
    }

    private dataTarget(): string {
        return domutils.getDatasetValue(this.element, "esTarget")
    }

    private handleClick(event: Event): any {
        this.targetElement().value = "";
    }

    private targetElement(): HTMLInputElement {
        return domutils.findFirst(document, this.dataTarget());
    }
}