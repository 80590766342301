import * as ajax from '../ajaxutil';
import * as domutils from '../domutils/index';

import EasyInputDate from '../domutils/easy-input-date';

const bootstrap = require('bootstrap');

class UriageKeshikomiDialog {

    modalElement: HTMLElement;
    modal: any;

    uriageId: number|undefined;
    reloadTarget: HTMLElement|undefined;

    constructor() {
        this.modalElement = domutils.requireObject(document.querySelector<HTMLElement>("#uriageKeshikomiDialog"));
        this.modal = bootstrap.Modal.getOrCreateInstance(this.modalElement);

        this.modalElement.addEventListener('hidden.bs.modal', event => {
            document.querySelectorAll<HTMLElement>("#summary").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });

            document.querySelectorAll<HTMLElement>("#memory").forEach((element) => {
                const reloadEvent = new CustomEvent("reload");
                element.dispatchEvent(reloadEvent);
            });
        });

        this.modalElement.querySelectorAll<HTMLElement>("[data-action=reload-uriage-keshikomi-dialog-body]").forEach((element) => this.handleReloadEditUriageDialogBody(element));
    }

    private getUriageId(): number {
        return domutils.requireObject(this.uriageId);
    }

    private getReloadTarget(): HTMLElement {
        return domutils.requireObject(this.reloadTarget);
    }

    show(uriageId: number, reloadTarget: HTMLElement) {
        this.uriageId = uriageId;
        this.reloadTarget = reloadTarget;

        this.reloadBody();
        this.modal.show();
    }

    private handleReloadEditUriageDialogBody(element: HTMLElement) {

        let afterLoad = () => {
            this.modalElement.querySelectorAll<HTMLInputElement>("input[type=date]").forEach((element) => new EasyInputDate(element));
            this.modalElement.querySelectorAll<HTMLElement>("[data-action=send-create-uriage-nyukingaku]").forEach((element) => this.handleNew(element));
            this.modalElement.querySelectorAll<HTMLElement>("[data-action=send-update-uriage-nyukingaku]").forEach((element) => this.handleUpdate(element));
            this.modalElement.querySelectorAll<HTMLElement>("[data-action=send-delete-uriage-nyukingaku]").forEach((element) => this.handleDelete(element));
        }

        ajax.setReload(element, afterLoad);
    }

    private handleNew(element: HTMLElement) {
        element.addEventListener("click", (ev) => {
            let afterLoad = (response: any) => {
                this.reloadBody();
                this.reloadOriginRecord();
            }

            ajax.setSendForm(element, undefined, afterLoad)
        });
    }

    private handleUpdate(element: HTMLElement) {
        element.addEventListener("change", (ev) => {
            let afterLoad = (response: any) => {
                this.reloadOriginRecord();
            }

            ajax.setSendForm(element, undefined, afterLoad)
        });
    }

    private handleDelete(element: HTMLElement) {
        element.addEventListener("click", (ev) => {
            let afterLoad = (response: any) => {
                this.reloadBody();
                this.reloadOriginRecord();
            }

            ajax.setSendForm(element, undefined, afterLoad)
        });
    }

    private reloadBody() {
        const table = domutils.requireObject(this.modalElement.querySelector("[data-action=reload-uriage-keshikomi-dialog-body]"));
        const reloadEvent = new CustomEvent("reload", { detail: { id: this.getUriageId() } });
        table.dispatchEvent(reloadEvent);
    }

   private reloadOriginRecord() {
        const reloadEvent = new CustomEvent("reload");
        this.getReloadTarget().dispatchEvent(reloadEvent);
    }

}

export default UriageKeshikomiDialog;