import * as domutils from '../domutils/index';

class DeleteUriageMeisai {
    parentElement: HTMLElement;
    deleteUriageMeisaiElement: HTMLElement;

    uriageMeisaiFormListElement: HTMLElement;
    uriageMeisaiFormItemElement: HTMLElement;
    uriageKingakuToElement: HTMLElement;

    constructor(parentElement: HTMLElement, deleteUriageMeisaiElement: HTMLElement) {
        this.parentElement = parentElement;
        this.deleteUriageMeisaiElement = deleteUriageMeisaiElement;

        this.uriageMeisaiFormListElement = domutils.requireObject(parentElement.querySelector<HTMLElement>("#uriageMeisaiFormList"));
        this.uriageMeisaiFormItemElement = domutils.requireObject(parentElement.querySelector<HTMLElement>("#uriageMeisaiFormItem"));
        this.uriageKingakuToElement = domutils.requireObject(parentElement.querySelector<HTMLElement>("#uriageKingakuTo"));

        this.deleteUriageMeisaiElement.addEventListener("click", (ev) => this.handleClick(ev));
    }

    handleClick(ev: MouseEvent) {
        if (!this.confirm(this.deleteUriageMeisaiElement)) {
            return;
        }

        let form = domutils.getForm(this.deleteUriageMeisaiElement);
        let formData = new FormData(form);

        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then(() => this.reload());
    }

    private confirm(element: Element) {
        if (domutils.hasDatasetKey(element, "confirm")) {
            return window.confirm(domutils.getDatasetValue(element, "confirm"));
        } else {
            return true;
        }
    }

    reload() {
        this.uriageMeisaiFormListElement.dispatchEvent(new CustomEvent("reload"));
        this.uriageMeisaiFormItemElement.dispatchEvent(new CustomEvent("reload"));
        this.uriageKingakuToElement.dispatchEvent(new CustomEvent("reload"));
    }
}

export default DeleteUriageMeisai;
