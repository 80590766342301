import * as lib from './lib';

function SelectUriageDialog(queryText, selectAction) {
	this.selectUriageDialogElement = lib.findFirstElement(document, "#selectUriageDialog");
	this.queryTextElement = lib.findFirstElement(this.selectUriageDialogElement, "#queryText");
	this.selectUriageDialogTableElement = lib.findFirstElement(this.selectUriageDialogElement, "#selectUriageDialogTable");

	this.modal = getModel(this.selectUriageDialogElement);
	this.show = show;
	this.reload = reload;
	this.hide = hide;

	this.queryTextElement.value = queryText;
	this.selectAction = selectAction;

	this.queryTextElement.addEventListener("keyup", (event) => {
		this.reload();
	});
}

function getModel(selectUriageDialogElement) {
	let modal = bootstrap.Modal.getInstance(selectUriageDialogElement);
	if (modal == null) {
		modal = new bootstrap.Modal(selectUriageDialogElement);
	}
	return modal;
}

function show() {
	this.modal.show();
	this.reload();
}

function reload() {
	fetch("/uriage/select_uriage_dialog_table?queryText=" + encodeURIComponent(this.queryTextElement.value))
	.then(response => response.text())
	.then(text => {
		this.selectUriageDialogTableElement.innerHTML = text;
		lib.findAllElement(this.selectUriageDialogTableElement, "[data-es-action=select]", (selectButtonElement) => {
			selectButtonElement.addEventListener("click", (event) => {
				this.selectAction(selectButtonElement);
			});
		});
	});
}

function hide() {
	this.modal.hide();
}

window.addEventListener('DOMContentLoaded', (event) => {

    lib.findAllElement(document, "#motoDenpyoNo", (motoDenpyoNoElement) => {
		motoDenpyoNoElement.addEventListener("click", (event) => {

			let uriageKingakuElement = lib.findFirstElement(document, "#uriageKingaku");
			let queryText = -1 * uriageKingakuElement.value

			let selectUriageDialog = new SelectUriageDialog(queryText, (selectButtonElement) => {
				motoDenpyoNoElement.value = selectButtonElement.dataset.esDenpyoNo;
				selectUriageDialog.hide();
			});

			selectUriageDialog.show();
        });
	});

    lib.findAllElement(document, "#akaDenpyoNo", (akaDenpyoNoElement) => {
		akaDenpyoNoElement.addEventListener("click", (event) => {
			let selectUriageDialog = new SelectUriageDialog("", (selectButtonElement) => {
				let map = new Map();
				for (let denpyoNo of akaDenpyoNoElement.value.split(",")) {
					if (!!denpyoNo.trim()) {
						map.set(denpyoNo.trim(), true);
					}
				}
				map.set(selectButtonElement.dataset.esDenpyoNo, true);

				let array = new Array();
				for (let key of map.keys()) {
					array.push(key);
				}

				akaDenpyoNoElement.value = array.join(",");
			});

			selectUriageDialog.show();
        });
	});
});
