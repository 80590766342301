import ObjectUtils from "../lib/ObjectUtils";

const bootstrap = require('bootstrap');

export default class ConfirmDialog {

    private static instance: ConfirmDialog;

    static getInstance() {
        if (!this.instance) {
            let element = ObjectUtils.require(document.querySelector<HTMLElement>("#confirmDialog"), HTMLElement);
            this.instance = new ConfirmDialog(element);
        }
        return this.instance;
    }

    private confirmDialogElement: HTMLElement;                                     // 基準要素
    private confirmedElement: HTMLElement;
    private modal: any;

    private confirmedEventListener?: EventListener;

    private constructor(confirmDialogElement: HTMLElement) {
        this.confirmDialogElement = confirmDialogElement;
        this.confirmedElement = ObjectUtils.require(this.confirmDialogElement.querySelector<HTMLElement>("#confirmDialogYesButton"), HTMLElement);

        this.modal = bootstrap.Modal.getInstance(this.confirmDialogElement);
		if (this.modal == null) {
			this.modal = new bootstrap.Modal(this.confirmDialogElement);
        }

        this.confirmDialogElement.addEventListener("hide.bs.modal", event => this.handleHideBsModal(event));
        this.confirmedElement.addEventListener('click', (event) => this.handleConfirmed(event));
    }

    public show(message: string, confirmedEventListener: EventListener) {
        this.confirmedEventListener = (event) => confirmedEventListener(event);

        let modalBody = ObjectUtils.require(this.confirmDialogElement.querySelector<HTMLElement>(".modal-body"), HTMLElement);
        modalBody.textContent = message;

        this.modal.show();
    }

    private handleConfirmed(event: Event) {
        if (this.confirmedEventListener) {
            this.confirmedEventListener(event);
        }

        this.modal.hide();
    }

    private handleHideBsModal(event: Event): void {
        if (this.confirmedEventListener) {
            this.confirmedElement.removeEventListener('click', this.confirmedEventListener);
            this.confirmedEventListener = undefined;
        }
    }
}
