import * as isearch from './isearch';
import * as domutil from './domutil';
import * as date from './domutils/easy-input-date';
import * as month from './domutils/easy-input-month';
import * as action from './action/index';
import * as lib from './lib';
import * as clear from './action/clear-button';

import ConfirmDialogButton from './action/confirm-dialog-button';

window.addEventListener('DOMContentLoaded', (event) => {
    bumonCodeIsearch();
    nohinsakiCodeIsearch();
    shohinCodeIsearch();
    sokoCodeIsearch();
    tantoshaCodeIsearch();
    tokuisakiCodeIsearch();
    clearSearchCondition();
    times();
    clearButton();

    date.setupEasyInputDate();
    month.setupEasyInputMonth();

	document.querySelectorAll("[data-es-confirm-dialog]").forEach((element) => {
		new ConfirmDialogButton(element);
	});
});

export function bumonCodeIsearch(selectors = "[name=bumonCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(bumonCode => {
        const bumonISearch = new isearch.NewObject(bumonCode, "/api/bumon", "bumonCode", "bumonMei");
        bumonISearch.valueElement.addEventListener("isearch-change", (event) => {
            const value = event.hasOwnProperty("isearchItem") ? event.isearchItem["bumonMei"] : "";
            domutil.setValue(document, bumonCode.dataset.bumonMei, value);
        });
    });
}

export function nohinsakiCodeIsearch(selectors = "[name=nohinsakiCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(nohinsakiCode => {
        const nohinsakiISearch = new isearch.NewObject(nohinsakiCode, "/api/nohinsaki", "nohinsakiCode", "nohinsakiMei");
        nohinsakiISearch.valueElement.addEventListener("isearch-change", (event) => {
            const value = event.hasOwnProperty("isearchItem") ? event.isearchItem["nohinsakiMei"] : "";
            domutil.setValue(document, nohinsakiCode.dataset.nohinsakiMei, value);
        });
    });
}

// 商品のインクリメンタルサーチ
export function shohinCodeIsearch(selectors = "[name=shohinCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(shohinCode => {
        const shohinISearch = new isearch.NewObject(shohinCode, "/api/shohin/search", "shohinCode", "shohinMei");
        shohinISearch.valueElement.addEventListener("isearch-change", (event) => {
            const value = event.hasOwnProperty("isearchItem") ? event.isearchItem["shohinMei"] : "";
            domutil.setValue(document, shohinCode.dataset.shohinMei, value);
        });
    });
}

// 倉庫のインクリメンタルサーチ
export function sokoCodeIsearch(selectors = "[name=sokoCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(sokoCode => {
        const sokoISearch = new isearch.NewObject(sokoCode, "/api/soko", "sokoCode", "sokoMei");
        sokoISearch.valueElement.addEventListener("isearch-change", (event) => {
            const value = event.hasOwnProperty("isearchItem") ? event.isearchItem["sokoMei"] : "";
            domutil.setValue(document, sokoCode.dataset.sokoMei, value);
        });
    });
}

export function tantoshaCodeIsearch(selectors = "[name=tantoshaCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(tantoshaCode => {
        const tantoshaISearch = new isearch.NewObject(tantoshaCode, "/api/tantosha", "tantoshaCode", "tantoshaMei");
        tantoshaISearch.valueElement.addEventListener("isearch-change", (event) => {
            const value = event.hasOwnProperty("isearchItem") ? event.isearchItem["tantoshaMei"] : "";
            domutil.setValue(document, tantoshaCode.dataset.tantoshaMei, value);
        });
    });
}


export function tokuisakiCodeIsearch(selectors = "[name=tokuisakiCode][data-action~=isearch]") {
    document.querySelectorAll(selectors).forEach(tokuisakiCodeElement => {
        const tokuisakiISearch = new isearch.NewObject(tokuisakiCodeElement, "/api/tokuisaki", "tokuisakiCode", "tokuisakiHyojiMei");
        tokuisakiISearch.valueElement.addEventListener("isearch-change", (event) => {
            let tokuisakiCode = "";
            let tokuisakiMei = "";
            let shiharaihoho = "";
            let tokuisakiShurui = "";
            let seikyusakiKubun = "";

            if (event.hasOwnProperty("isearchItem")) {
                tokuisakiCode = event.isearchItem["tokuisakiCode"];
                tokuisakiMei = event.isearchItem["tokuisakiMei"];
                shiharaihoho = event.isearchItem["shiharaihoho"];
                tokuisakiShurui = event.isearchItem["tokuisakiShurui"];
                seikyusakiKubun = event.isearchItem["seikyusakiKubun"];
            }

            domutil.setHref(document, tokuisakiCodeElement.dataset.tokuisakiCode, "/tokuisaki/" + tokuisakiCode);
            domutil.setValue(document, tokuisakiCodeElement.dataset.tokuisakiMei, tokuisakiMei);
            domutil.setValue(document, tokuisakiCodeElement.dataset.shiharaihoho, shiharaihoho);
            domutil.setValue(document, tokuisakiCodeElement.dataset.tokuisakiShurui, tokuisakiShurui);
            domutil.setValue(document, tokuisakiCodeElement.dataset.seikyusakiKubun, seikyusakiKubun);

            document.querySelectorAll(tokuisakiCodeElement.dataset.tokuisakiCode).forEach((element) => {
                if (event.hasOwnProperty("isearchItem")) {
                    domutil.display(element);
                } else {
                    domutil.displayNone(element);
                }
            });
        });
    });
}


/**
 * data-action=send-form
 *
 * data-event(*)  click、change など
 * data-message   確認メッセージがあれば設定する。
 * data-reload    reload を発生させるエレメントがあれば設定する。
 * data-timeout   setTimeout() する必要があるときに設定する。
 *
 * element.beforeSendForm  設定しておくと send() 前に formData を引数に呼び出される。
 */
export function sendForm(selectors = "[data-action~=send-form]") {
    document.querySelectorAll(selectors).forEach(element => {

        // data-event
        if (!element.dataset.event) {
            console.error("data-event is not set. : " + element.outerHTML);
        }

        element.addEventListener(element.dataset.event, () => {
            if (!confirm()) {
                return;
            }

            const request = new XMLHttpRequest();
            request.open(element.form.method, element.form.action);
            request.addEventListener('load', () => {
                let data = JSON.parse(request.response);

                console.log(data);

                reload();

                if (element.afterLoad) {
                    element.afterLoad(data);
                }
            });

            timeout(() => {
                let formData = new FormData(element.form);

                if (element.beforeSendForm) {
                    element.beforeSendForm(formData);
                }

                request.send(formData);
            });
        });

        function confirm() {
            if (element.dataset.confirm) {
                return window.confirm(element.dataset.confirm);
            } else {
                return true;
            }
        }

        function reload() {
            document.querySelectorAll(element.dataset.reload).forEach((target) => {
                const event = new CustomEvent("reload");
                target.dispatchEvent(event);
            });
        }

        function timeout(callback) {
            if (element.dataset.timeout) {
                // TODO setTimeout() だとタイミング次第。
                window.setTimeout(() => {
                    callback();
                }, element.dataset.timeout);
            } else {
                callback();
            }
        }
    });
}

export function times(selectors = "[data-action~=times]") {
    document.querySelectorAll(selectors).forEach((target) => {
        new action.Times(target)
    });
}

export function zeiKeisanHohoChainValue(selectors = "[name=zeiKeisanHoho][data-action~=chain-value]") {
    document.querySelectorAll(selectors).forEach(zeiKeisanHoho => {
        zeiKeisanHoho.addEventListener("change", () => {
            document.querySelectorAll("[name=kazeiKubun]").forEach(kazeiKubun => {
                kazeiKubun.value = zeiKeisanHoho.selectedOptions[0].dataset.kazeiKubun;
                const change = new Event("change");
                kazeiKubun.dispatchEvent(change);
            });
        });
    });
}

export function zeiritsuChainValue(selectors = "[name=zeiritsu][data-action=chain-value]") {
    document.querySelectorAll(selectors).forEach(zeiritsu => {
        zeiritsu.addEventListener("change", (event) => {
            document.querySelectorAll("[name^=zeiritsu]").forEach(meisaiZeiritsu => {
                if (zeiritsu != meisaiZeiritsu) {
                    meisaiZeiritsu.value = zeiritsu.value;
                    const change = new Event("change");
                    meisaiZeiritsu.dispatchEvent(change);
                }
            });
        });
    });
}

export function clearSearchCondition(selectors = "[data-action~=clear-search-condition]") {
    document.querySelectorAll(selectors).forEach(button => {
        button.addEventListener("click", (ev) => {
            document.querySelectorAll(button.dataset.clearSearchConditionForm).forEach(form => {
                form.querySelectorAll("input[type=date],input[type=month],input[type=text],select").forEach(e => {
                    if (e instanceof HTMLInputElement) {
                        if (e.dataset.defaultValue) {
                            e.value = e.dataset.defaultValue;
                        } else {
                            e.value = "";
                        }
                    } else if (e instanceof HTMLSelectElement) {
                        if (e.dataset.defaultValue) {
                            e.value = e.dataset.defaultValue;
                        } else {
                            e.selectedIndex = 0;
                        }
                    }
                });
            });
        });
    });
}

export function clearButton() {
    lib.findAllElement(document, "[data-es-action=clear]", (element) => {
        new clear.ClearButton(element);
	});
}