import * as ajaxutil from './ajaxutil';
import * as domutil from './domutil';

const bootstrap = require('bootstrap');

class UriageIndex {

    bodyElement?: HTMLElement;                                     // 基準要素

    constructor() {
        this.bodyElement = domutil.findFirst(document, "#uriageIndex");
        if (this.bodyElement == undefined) {
            return;
        }
    }

}

window.addEventListener('DOMContentLoaded', (event) => {
    const uriage = new UriageIndex();
});