export function getValue(element: Element): string {
    if (element instanceof HTMLInputElement) {
        return element.value;
    }

    if (element instanceof HTMLSelectElement) {
        return element.value;
    }

    return element.innerHTML;
}

export function getNumber(element: Element): Number {
    return Number(getValue(element));
}

export function isBlank(element: Element): boolean {
    if (!(element instanceof Element)) {
        throw new Error("element is not Element.");
    }
    let value = getValue(element);
    return String(value).trim() == "";
}

export function getDatasetValue(element: Element, key: string): string {
    if (!(element instanceof HTMLElement)) {
        throw new Error("element is not HTMLElement.");
    }

    let value = element.dataset[key];
    if (value == undefined) {
        throw new Error("key not found.: " + key);
    }

    return value;
}

export function hasDatasetKey(element: Element, key: string): boolean {
    if (!(element instanceof HTMLElement)) {
        throw new Error("element is not HTMLElement.");
    }

    return key in element.dataset;
}

export function setValue(element: Element, value: any, event?: string): void {
    if (element instanceof HTMLInputElement) {
        element.value = value;

        if (event != undefined) {
           element.dispatchEvent(new CustomEvent(event));
        }
        return;
    }

    if (element instanceof HTMLSelectElement) {
        let o = element.querySelector("[value="+ value + "]")
        if (o instanceof HTMLOptionElement) {
            o.selected = true;
        }

        if (event != undefined) {
            element.dispatchEvent(new CustomEvent(event));
        }
        return;
    }

    element.innerHTML = value;
}

export function clearValue(element: Element, event?: string): void {
    if (element instanceof HTMLInputElement) {
        element.value = "";

        if (event != undefined) {
           element.dispatchEvent(new CustomEvent(event));
        }
        return;
    }

    if (element instanceof HTMLSelectElement) {
        throw new Error("Not implements.");
    }

    element.innerHTML = "";
}

export function getSelectedOption(element: Element): HTMLOptionElement {
    if (!(element instanceof HTMLSelectElement)) {
        throw new Error("element is not HTMLOptionElement.");
    }
    return element.selectedOptions[0];
}

export function getForm(element: Element): HTMLFormElement {

    if (element instanceof HTMLButtonElement && element.form != null) {
        return element.form;
    }

    if (!element.hasAttribute("form")) {
        throw new Error("element does not have a form.");
    }

    let form = (element as any).form;
    if (!(form instanceof HTMLFormElement)) {
        throw new Error("element does not have a form.");
    }
    return form;
}

export function findFirst<T extends Element>(parent: Element|Document, selectors: string, callback?: (element: T) => any): T {
    let element = parent.querySelector(selectors);
    if (element == null) {
        throw new Error("element not found.: " + selectors);
    }

    if (callback !== undefined) {
        callback(element as T);
    }

    return element as T;
}

export function findAndValue(parent: Element|Document, selectors: string): string {
    return getValue(findFirst(parent, selectors));
}

export function findAndNumber(parent: Element|Document, selectors: string): Number {
    return getNumber(findFirst(parent, selectors));
}

export function requireObject<T>(element: T|null|undefined): T {
    if (element == null || element == undefined) {
        throw new Error("element required");
    }
    return element;
}