class ObjectUtils {
    static requireString(obj: any): string {
        if (typeof(obj) !== "string") {
            throw Error();
        }

        return obj;
    }

    static require<T>(obj: any, c: { new(): T}): T {
        if (!(obj instanceof(c))) {
            throw Error();
        }

        return obj;
    }
}

export default ObjectUtils;
