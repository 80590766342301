import * as domutils from '../domutils/index';

const isearch = require('../isearch');

class ShohinCodeIncrementalSearch {
    parentElement: HTMLElement;
    shohinCodeElement: HTMLElement;

    shohinMeiElement: HTMLElement;

    nohinsakiCodeElement: HTMLElement;
    tokuisakiCodeElement: HTMLElement;
    bumonCodeElement: HTMLElement;

    zeiKeisanHohoElement: HTMLElement;
    zeiritsuElement: HTMLElement;

    uriageTankaElement: HTMLElement;
    genkaTankaElement: HTMLElement;
    sokoCodeElement: HTMLElement;
    sokoMeiElement: HTMLElement;
    uriageKingakuElement: HTMLElement;

    uriageKingakuToElement: HTMLElement;

    timeout: string;

    constructor(parentElement: HTMLElement, shohinCodeElement: HTMLElement) {
        this.parentElement = parentElement;
        this.shohinCodeElement = shohinCodeElement;

        this.shohinMeiElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "shohinMei")));

        this.nohinsakiCodeElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#nohinsakiCode"));
        this.tokuisakiCodeElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#tokuisakiCode"));
        this.bumonCodeElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#bumonCode"));

        this.zeiKeisanHohoElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#zeiKeisanHoho"));
        this.zeiritsuElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#zeiritsu"));

        this.uriageTankaElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "uriageTanka")));
        this.genkaTankaElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "genkaTanka")));
        this.sokoCodeElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "sokoCode")));
        this.sokoMeiElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "sokoMei")));
        this.uriageKingakuElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>(domutils.getDatasetValue(this.shohinCodeElement, "uriageKingaku")));

        this.timeout = domutils.getDatasetValue(this.shohinCodeElement, "timeout");

        this.uriageKingakuToElement = domutils.requireObject(this.parentElement.querySelector<HTMLElement>("#uriageKingakuTo"));

        const shohinISearch = new isearch.NewObject(this.shohinCodeElement, "/api/shohin/search", "shohinCode", "shohinMei");
        shohinISearch.valueElement.addEventListener("isearch-change", (event: CustomEvent) => this.handleIsearchChange(event));
    }

    handleIsearchChange(event: CustomEvent) {

        if (event.detail === null) {
            this.resetKingaku();
            return;
        }
        this.setKingaku(event);
    }

    resetKingaku() {
        domutils.setValue(this.shohinMeiElement, "");
        domutils.setValue(this.uriageTankaElement, 0);
        domutils.setValue(this.genkaTankaElement, 0);
        domutils.setValue(this.sokoCodeElement, "");
        domutils.setValue(this.sokoMeiElement, "");

        this.uriageKingakuElement.dispatchEvent(new CustomEvent("times"));

        this.sendForm();
    }

    setKingaku(event: CustomEvent) {
        domutils.setValue(this.shohinMeiElement, event.detail["shohinMei"]);

        let params = new URLSearchParams();
        params.append("nohinsakiCode", domutils.getValue(this.nohinsakiCodeElement));
        params.append("tokuisakiCode", domutils.getValue(this.tokuisakiCodeElement));
        params.append("bumonCode", domutils.getValue(this.bumonCodeElement));
        params.append("shohinCode", domutils.getValue(this.shohinCodeElement));

        fetch("/api/uriage_meisai_history?" + params.toString())
        .then(response => response.json())
        .then((json) => {
            let uriageTanka = 0;
            let genkaTanka = 0;
            let sokoCode = "";
            let sokoMei = "";

            let selectedOption = domutils.getSelectedOption(this.zeiKeisanHohoElement);
            let kazeiKubun = domutils.getDatasetValue(selectedOption, "kazeiKubun");
            let zeiritsu = domutils.getValue(this.zeiritsuElement);

            if (kazeiKubun == "UCHIZEI" && zeiritsu == "TEN") {
                genkaTanka = Math.round(event.detail["genkaTanka"] * 1.1);
            } else if (kazeiKubun == "UCHIZEI" && zeiritsu == "EIGHT") {
                genkaTanka = Math.round(event.detail["genkaTanka"] * 1.08);
            } else {
                genkaTanka = event.detail["genkaTanka"];
            }

            if (json.status === "ok" ) {
                this.parentElement.querySelectorAll("#zeiKeisanHoho").forEach((element) => {
                    let selectedOption = domutils.getSelectedOption(element);
                    let kazeiKubun = domutils.getDatasetValue(selectedOption, "kazeiKubun");

                    if (kazeiKubun == "UCHIZEI") {
                        uriageTanka = json.zeikomiUriageTanka;
                        genkaTanka = json.zeikomiGenkaTanka;
                    } else {
                        uriageTanka = json.zeinukiUriageTanka;
                        genkaTanka = json.zeinukiGenkaTanka;
                    }
                    sokoCode = json.sokoCode;
                    sokoMei = json.sokoMei;
                });
            }

            domutils.setValue(this.uriageTankaElement, uriageTanka);
            domutils.setValue(this.genkaTankaElement, genkaTanka);
            domutils.setValue(this.sokoCodeElement, sokoCode);
            domutils.setValue(this.sokoMeiElement, sokoMei);

            this.uriageKingakuElement.dispatchEvent(new CustomEvent("times"));
        }).then(() => this.sendForm());
    }

    sendForm() {
        let form = domutils.getForm(this.shohinCodeElement);
        let formData = new FormData(form);

        fetch(form.action, {
            method: form.method,
            body: formData
        })
        .then(response => response.json())
        .then(() => this.reload());
    }

    reload() {
        this.uriageKingakuToElement.dispatchEvent(new CustomEvent("reload"));
    }

}

export default ShohinCodeIncrementalSearch;
