import * as domutils from './domutils/index';

class SeikyushoIndex {

    contentsElement: HTMLElement|null;                          // 基準要素

    constructor() {
        this.contentsElement = document.querySelector("#seikyushoIndex");
        if (this.contentsElement === null) {
            return;
        }

        this.getContentsElement().querySelectorAll("[data-action~=chain-clicked]").forEach((element) => {
            new ChainClickedAction(this, element);
        })

        console.info("initialized: SeikyushoIndex")
    }

    getContentsElement(): HTMLElement {
        return domutils.requireObject(this.contentsElement);
    }

}

class ChainClickedAction {
    context: SeikyushoIndex;
    element: Element;

    constructor(context: SeikyushoIndex, element: Element) {
        this.context = context;
        this.element = element;

        element.addEventListener("click", event => this.handleChange(event));

        console.info("initialized: ChainClickedAction")
    }

    private handleChange(event: Event): any {
        this.context.getContentsElement().querySelectorAll<HTMLElement>("[id^=batchExcel]").forEach((element) => {
            element.click();
        });
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    new SeikyushoIndex();
});