import ConfirmSubmitButton from "../action/ConfirmSubmitButton";
import KojinmukeShimeSeikyushoIndexHikiotoshibi from "./KojinmukeShimeSeikyushoIndexHikiotoshibi";

class KojinmukeShimeSeikyushoIndex {
    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=KojinmukeShimeSeikyushoIndex]").forEach((element) => {
            new KojinmukeShimeSeikyushoIndex(element);
        });
    }

    private kojinmukeShimeSeikyushoIndexElement: HTMLElement;

    constructor(kojinmukeShimeSeikyushoIndexElement: HTMLElement) {
        this.kojinmukeShimeSeikyushoIndexElement = kojinmukeShimeSeikyushoIndexElement;

        KojinmukeShimeSeikyushoIndexHikiotoshibi.initComponent(this.kojinmukeShimeSeikyushoIndexElement);
        ConfirmSubmitButton.initComponent(this.kojinmukeShimeSeikyushoIndexElement);
    }
}

export default KojinmukeShimeSeikyushoIndex;
