import * as domutils from './domutils/index';
import * as action from './action/set-haitatsubi';
import * as gomibukuro from './action/set-gomibukuro';
import * as clear from './action/clear-button';

const bootstrap = require('bootstrap');

class OmutsuKyufuTokuisaki {

    contents: HTMLElement;
    omutsuKyufuTokuisakiDialog: HTMLElement;

    omutsuKyufuTokuisakiNewDialog: HTMLElement;

    omutsuKyufuTokuisakiDialogModal: any;
    omutsuKyufuTokuisakiNewDialogModal: any;


    constructor(contents: HTMLElement) {
        this.contents = contents;

        this.contents.querySelectorAll<HTMLElement>("[data-action~=show-omutsu-kyufu-tokuisaki-dialog]").forEach((element) => this.handleShowOmutsuKyufuTokuisakiDialog(element));
        this.contents.querySelectorAll<HTMLElement>("[data-action~=new-omutsu-kyufu-tokuisaki-dialog]").forEach((element) => this.handleNewOmutsuKyufuTokuisakiDialog(element));

        this.omutsuKyufuTokuisakiDialog = domutils.requireObject(this.contents.querySelector<HTMLElement>("#omutsuKyufuTokuisakiDialog"));

        this.omutsuKyufuTokuisakiDialogModal = bootstrap.Modal.getOrCreateInstance(this.omutsuKyufuTokuisakiDialog);

        this.omutsuKyufuTokuisakiNewDialog = domutils.requireObject(this.contents.querySelector<HTMLElement>("#omutsuKyufuTokuisakiNewDialog"));

        this.omutsuKyufuTokuisakiNewDialogModal = bootstrap.Modal.getOrCreateInstance(this.omutsuKyufuTokuisakiNewDialog);
    }

    private handleShowOmutsuKyufuTokuisakiDialog(element: HTMLElement) {

        element.addEventListener("click", (ev) => {
            if (!(element instanceof HTMLElement) || element.parentElement == undefined || element.parentElement.dataset.omutsuKyufuTokuisakiDialogPath == undefined) {
                return;
            }

            fetch(element.parentElement.dataset.omutsuKyufuTokuisakiDialogPath)
                .then((response) => response.text())
                .then((text) => {
                    let omutsuKyufuTokuisakiDialogBody = domutils.requireObject(this.contents.querySelector<HTMLElement>("#omutsuKyufuTokuisakiDialogBody"));

                    omutsuKyufuTokuisakiDialogBody.innerHTML = text;

                    this.omutsuKyufuTokuisakiDialog.querySelectorAll("[data-action~=set-haitatsubi]").forEach((element: Element) => {
                        const setHaitatsubi = new action.SetHaitatsubi(element);
                    });

                    this.omutsuKyufuTokuisakiDialog.querySelectorAll("[data-action~=set-gomibukuro]").forEach((element: Element) => {
                        const setGomibukuro = new gomibukuro.SetGomibukuro(element);
                    });

                    this.omutsuKyufuTokuisakiDialog.querySelectorAll("[data-es-action=clear]").forEach((element: Element) => {
                        const setGomibukuro = new clear.ClearButton(element);
                    });

                    this.omutsuKyufuTokuisakiDialogModal.show();
                });
        });
    }

    private handleNewOmutsuKyufuTokuisakiDialog(element: Element) {
        element.addEventListener("click", (ev) => {
            this.omutsuKyufuTokuisakiNewDialog.querySelectorAll("[data-action~=set-haitatsubi]").forEach((element: Element) => {
                const setHaitatsubi = new action.SetHaitatsubi(element);
            });

            this.omutsuKyufuTokuisakiNewDialog.querySelectorAll("[data-action~=set-gomibukuro]").forEach((element: Element) => {
                const setGomibukuro = new gomibukuro.SetGomibukuro(element);
            });

            this.omutsuKyufuTokuisakiNewDialog.querySelectorAll("[data-es-action=clear]").forEach((element: Element) => {
                const setGomibukuro = new clear.ClearButton(element);
            });

            this.omutsuKyufuTokuisakiNewDialogModal.show();
        });
    }

}

window.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll<HTMLElement>("#omutsuKyufuTokuisakiIndex").forEach((element) => {
        new OmutsuKyufuTokuisaki(element);
    });
});
