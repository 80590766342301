import ObjectUtils from "../lib/ObjectUtils";

class ISearch {

    private valueElement: HTMLInputElement;
    private textElement: HTMLInputElement;
    private initValue: string;
    private apiUrl: URL;
    private valueAttr: string;
    private textAttr: string;
    private dropdownMenuElement: HTMLElement;
    private clearButtonElement: HTMLElement;
    private data: any;

    constructor(valueElement: HTMLInputElement, apiPath: URL, valueAttr: string, textAttr: string, textElement: HTMLInputElement) {

        this.valueElement = valueElement;
        this.textElement = textElement;

        this.initValue = this.valueElement.value;  // 値を初期状態に戻すときに使用する。

        this.apiUrl = apiPath;

        this.valueAttr = valueAttr;
        this.textAttr = textAttr;

        let dropdownElement = this.query_parent(this.valueElement, ".dropdown");

        this.dropdownMenuElement = ObjectUtils.require(dropdownElement.querySelector(".dropdown-menu"), HTMLElement);
        this.clearButtonElement = ObjectUtils.require(dropdownElement.querySelector("[data-isearch-clear]"), HTMLElement);

        this.valueElement.addEventListener("keydown", (event) => {
            if (event.key == "Escape") {
                event.preventDefault();
                this.escapeKeydown(event);
                return;
            }

            if (event.key == "ArrowUp") {
                event.preventDefault();
                this.arrowUpKeydown(event);
                return;
            }

            if (event.key == "ArrowDown") {
                event.preventDefault();
                this.arrowDownKeydown(event);
                return;
            }

            if (event.key == "Enter") {
                event.preventDefault();
                this.enterKeydown(event);
                return;
            }

        });
        this.valueElement.addEventListener("input", (event) => {
            event.preventDefault();
            this.inputValueElement(event);
        });

        this.valueElement.addEventListener("blur", (event) => {
            event.preventDefault();
            // TODO setTimeout() だとタイミング次第。
            let obj = this;
            window.setTimeout(() => {
                obj.changeValueElement(event);
            }, 200);
        });

        this.dropdownMenuElement.addEventListener("click", (event) => {
            event.preventDefault();
            this.clickDropdownMenu(event);
        });

        this.clearButtonElement.addEventListener("click", (event) => {
            event.preventDefault();
            this.clickClearButton(event);
        });
    }

    private escapeKeydown(event: KeyboardEvent) {
        this.setInitValue();
    }

    private arrowUpKeydown(event: KeyboardEvent) {
        let active = this.dropdownMenuElement.querySelector(".active");  // a タグ
        if (active) {
            let parent = ObjectUtils.require(active.parentElement, HTMLElement);  // li タグ
            if (parent.previousElementSibling) {
                active.classList.remove("active");

                ObjectUtils.require(parent.previousElementSibling.firstChild, HTMLElement).classList.add("active");
                (parent.previousElementSibling as any).scrollIntoViewIfNeeded();
            }
        }
    }

    private arrowDownKeydown(event: KeyboardEvent) {
        let active = this.dropdownMenuElement.querySelector(".active");  // a タグ
        if (active) {
            let parent = ObjectUtils.require(active.parentElement, HTMLElement);  // li タグ
            if (parent.nextElementSibling) {
                active.classList.remove("active");
                ObjectUtils.require(parent.nextElementSibling.firstChild, HTMLElement).classList.add("active");
                (parent.nextElementSibling as any).scrollIntoViewIfNeeded();
            }
        } else {
            let first = this.dropdownMenuElement.querySelector("a");
            if (first) {
                first.classList.add("active");
                (first.parentElement as any).scrollIntoViewIfNeeded();
            }
        }
    }

    private enterKeydown(event: KeyboardEvent) {
        let blurEvent = new CustomEvent("blur");
        this.valueElement.dispatchEvent(blurEvent);
    }

    private changeValueElement(event: FocusEvent) {
        event.stopImmediatePropagation();

        if (!this.dropdownMenuElement.classList.contains("show")) {
            return;
        }

        let active: Element|null = null;
        let index: number;
        for (index = 0; index < this.dropdownMenuElement.children.length; index += 1) {
            active = this.dropdownMenuElement.children[index].querySelector(".active");
            if (active) {
                break;
            }
        }

        if (active) {
            let item = this.data[index];
            this.setValueAndItem(item[this.valueAttr], item[this.textAttr], item);
        } else {
            let menuItems = this.dropdownMenuElement.querySelectorAll(".dropdown-item");
            if (menuItems.length == 1) {
                let item = this.data[0];
                this.setValueAndItem(item[this.valueAttr], item[this.textAttr], item);
            } else if (menuItems.length == null || menuItems.length > 1) {
                this.setEmptyValue();
            }
        }
        this.hideDropdownMenu()
    }

    private inputValueElement(event: Event) {
        let query = this.valueElement.value.trim().replace(/\s+/g, ' ');

        if (0 < query.length) {
            this.executeQuery(query);

        } else if (query.length == 0) {
            this.hideDropdownMenu();
        }
    }

    private clickDropdownMenu(event: MouseEvent) {
        var active = this.dropdownMenuElement.querySelector(".active");

        if (active && event.target != active) {
            active.classList.remove("active");
        }

        ObjectUtils.require(event.target, HTMLElement).classList.add("active");
        (event.target as any).scrollIntoViewIfNeeded();
    }

    private clickClearButton(event: MouseEvent) {
        this.setValueAndItem("", "", null);
        this.valueElement.focus();
    }

    private async executeQuery(query: string) {
        this.apiUrl.searchParams.set("q", query)

        let response = await fetch(this.apiUrl);
        this.data = await response.json();

        let elements = this.createDropdownItemElements();

        this.remove_child_nodes(this.dropdownMenuElement);

        if (0 < elements.length) {
            elements.forEach((list_item: any) => {
                this.dropdownMenuElement.appendChild(list_item);
            });

            this.dropdownMenuElement.classList.add("show");
        }
    }

    private hideDropdownMenu() {
        this.remove_child_nodes(this.dropdownMenuElement);
        this.dropdownMenuElement.classList.remove("show");
    }

    private createDropdownItemElements() {
        if (this.data)

        return this.data.map((item: any) => {
            let dropdownItem = document.createElement("a");
            dropdownItem.classList.add("dropdown-item");
            dropdownItem.text = item[this.valueAttr] + " " + item[this.textAttr];

            let li = document.createElement("li")
            li.appendChild(dropdownItem);
            return li;
        });
    }

    private setInitValue() {
        this.valueElement.value = this.initValue;
        this.hideDropdownMenu();
    }

    private setEmptyValue() {
        this.valueElement.value = "";
        this.hideDropdownMenu();
    }

    private setValueAndItem(value: string, text: string, item: any) {
        let fireEvent = value != this.valueElement.value;

        this.valueElement.value = value;
        this.textElement.value = text;
        this.initValue = value;

        if (fireEvent) {
            let event = new Event("change");
            this.valueElement.dispatchEvent(event);
        }

        let isearchChangeEvent = new CustomEvent("isearch-change", { detail: item, bubbles: true });

        this.valueElement.dispatchEvent(isearchChangeEvent);
    }

    private query_parent(source: HTMLElement, selector: string) {
        var target = ObjectUtils.require(source.parentElement, HTMLElement);
        while (!target.matches(selector)) {
            target = ObjectUtils.require(target.parentElement, HTMLElement);
        }
        return target;
    }

    private remove_child_nodes(element: HTMLElement) {
        while (element.firstChild) {
            element.removeChild(element.firstChild);
        }
    }
}

export default ISearch;