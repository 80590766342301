export default class StringUtils {

    static isBlank(s: string): boolean {
        return s === null || s === undefined || s.trim() === '';
    }

    static isNotBlank(s: string): boolean {
        return !this.isBlank(s);
    }

    static require(obj: any): string {
        if (typeof(obj) !== "string") {
            throw Error();
        }

        return obj;
    }
}