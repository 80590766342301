import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class MitsumorigakuTo {
    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLElement>("[data-action=MitsumorigakuTo]").forEach((element) => {
            new MitsumorigakuTo(element, parentElement);
        });
    }

    private mitsumorigakuToElement: HTMLElement;
    private parentElement: HTMLElement;

    private constructor(mitsumorigakuToElement: HTMLElement, parentElement: HTMLElement) {

        this.mitsumorigakuToElement = mitsumorigakuToElement;
        this.parentElement = parentElement;

        this.mitsumorigakuToElement.addEventListener("reload", event => this.handleReload(event));
    }

    private async handleReload(event: Event) {
        let reloadPath = StringUtils.require(this.mitsumorigakuToElement.dataset["reloadPath"]);
        let url = new URL(location.origin + reloadPath);

        let kazeiKubunElement = ObjectUtils.require(document.querySelector("#kazeiKubun"), HTMLSelectElement);
        url.searchParams.append("kazeiKubun", kazeiKubunElement.value);

        let zeiritsuElement = ObjectUtils.require(document.querySelector("#zeiritsu"), HTMLSelectElement);
        url.searchParams.append("zeiritsu", zeiritsuElement.value);

        let response = await fetch(url);
        this.mitsumorigakuToElement.innerHTML = await response.text();
    }
}

export default MitsumorigakuTo;
