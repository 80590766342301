export function setupEasyInputMonth(selectors = "input[type=month]") {
    document.querySelectorAll(selectors).forEach(element => {
        new EasyInputMonth(element);
    });
}

const delimiterPattern = /^((?<year>\d{1,4})\D|)(?<month>\d{1,2})$/;
const fixedLengthPattern = /^(?<year>((\d{2}|)\d{2})|)(?<month>\d{2})$/;

class EasyInputMonth {

    private element: Element;                                     // 基準要素
    private keydowned: boolean;

    constructor(element: Element) {
        this.element = element;
        this.keydowned = false;

        if (this.getElement().readOnly) {
            return;
        }

        this.getElement().type = "text";
        this.getElement().addEventListener("blur", (event) => this.handleBlur(event));
        this.getElement().addEventListener("keydown", (event) => this.handleKeydown(event));
    }

    private getElement(): HTMLInputElement {
        if (!(this.element instanceof HTMLInputElement)) {
            throw new Error("illegal state exception");
        }

        return this.element;
    }

    private handleBlur(event: FocusEvent) {
        let err = this.recognize()
        if (err instanceof Error) {
            this.getElement().value = "";

            let event = new Event("change");
            this.getElement().dispatchEvent(event);
            return;
        }
    }

    private handleKeydown(event: KeyboardEvent) {
        if (event.key === "Enter" && this.keydowned) {
            let err = this.recognize()
            if (err instanceof Error) {
                event.preventDefault();
                return;
            }
            this.keydowned = false;
            event.preventDefault();
            return;
        }

        this.keydowned = true;
    }

    private recognize(): Error|null {
        let value = this.getElement().value;

        let execArray = this.patternExec(value);
        if (execArray === null) {
            return new Error();
        }

        let year = execArray.groups?.year;
        if (year === undefined) {
            year = "";
        } else if (0 < year.length) {
            year = year.padStart(2, "0");
        }

        let thisYear = new Date().getFullYear().toString();
        year = thisYear.substring(0, 4 - year.length) + year;

        let month = execArray.groups?.month;

        if (month === undefined) {
            return new Error();
        }

        month = month.padStart(2, "0");

        try {
            let d = new Date(year + "-" + month + "-01");
            this.getElement().value = d.toISOString().substring(0, 7);
        } catch (error) {
            return new Error();
        }

        return null;
    }

    private patternExec(value: string): RegExpExecArray | null {
        return delimiterPattern.exec(value) || fixedLengthPattern.exec(value);
    }

}

export default EasyInputMonth;
