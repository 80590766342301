import KazeiKubunAndZeiritsuElement from "./KazeiKubunAndZeiritsuElement";
import MitsumorigakuTo from "./MitsumorigakuTo";
import MitsumorishoMeisaiIndex from "./MitsumorishoMeisaiIndex";


class MitsumorishoNew {

    static initComponent() {
        document.querySelectorAll<HTMLElement>("[data-action=MitsumorishoNew]").forEach((element) => {
            new MitsumorishoNew(element);
        });
    }

    private mitsumorishoNewElement: HTMLElement;

    private constructor(mitsumorishoNewElement: HTMLElement) {
        this.mitsumorishoNewElement = mitsumorishoNewElement;

        KazeiKubunAndZeiritsuElement.initComponent(this.mitsumorishoNewElement);
        MitsumorishoMeisaiIndex.initComponent(this.mitsumorishoNewElement);
        MitsumorigakuTo.initComponent(this.mitsumorishoNewElement);
    }
}

export default MitsumorishoNew;
