import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";
import TaxUtils from "../lib/TaxUtils";

class MitsumorigakuElement {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=MitsumorigakuElement]").forEach((element) => {
            new MitsumorigakuElement(element, parentElement);
        });
    }

    private mitsumorigakuElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private tankaElement: HTMLInputElement;
    private suryoElement: HTMLInputElement;

    private constructor(MitsumorigakuElement: HTMLInputElement, parentElement: HTMLElement) {

        this.mitsumorigakuElement = MitsumorigakuElement;
        this.parentElement = parentElement;

        this.tankaElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=mitsumoriTanka]"), HTMLInputElement);
        this.suryoElement = ObjectUtils.require(this.parentElement.querySelector("[data-name=suryo]"), HTMLInputElement);

        this.mitsumorigakuElement.addEventListener("times", event => this.handleTimes(event));
    }

    private async handleTimes(event: Event) {
        let mitsumorigaku = Number(this.tankaElement.value) * Number(this.suryoElement.value);
        this.mitsumorigakuElement.value = NumberUtils.currency(mitsumorigaku);

        let postPath = StringUtils.require(this.mitsumorigakuElement.dataset["postPath"]);
        let url = new URL(location.origin + postPath);

        let formData = new FormData();

        let csrfElement = ObjectUtils.require(document.querySelector("[name=_csrf]"), HTMLInputElement);
        formData.append("_csrf", csrfElement.value);
        formData.append("suryo", this.suryoElement.value);
        formData.append("mitsumoriTanka", this.tankaElement.value);
        formData.append("mitsumorigaku", mitsumorigaku.toString());

        let response = await fetch(url, { method: "post", body: formData });
        let responseJson = await response.json();

        if (responseJson.status === "OK") {
            this.mitsumorigakuElement.classList.remove("is-invalid");

            let reloadTargetElement = ObjectUtils.require(document.querySelector("#mitsumorigakuTo"), HTMLElement);
            reloadTargetElement.dispatchEvent(new CustomEvent("reload"));
        } else {
            this.mitsumorigakuElement.classList.add("is-invalid");
        }
    }
}

export default MitsumorigakuElement;