import NumberUtils from "../lib/NumberUtils";
import ObjectUtils from "../lib/ObjectUtils";
import StringUtils from "../lib/StringUtils";

class KazeiKubunAndZeiritsuElement {

    static initComponent(parentElement: HTMLElement) {
        parentElement.querySelectorAll<HTMLInputElement>("[data-action=KazeiKubunAndZeiritsuElement]").forEach((element) => {
            new KazeiKubunAndZeiritsuElement(element, parentElement);
        });
    }

    private kazeiKubunAndZeiritsuElement: HTMLInputElement;
    private parentElement: HTMLElement;

    private constructor(KazeiKubunAndZeiritsuElement: HTMLInputElement, parentElement: HTMLElement) {

        this.kazeiKubunAndZeiritsuElement = KazeiKubunAndZeiritsuElement;
        this.parentElement = parentElement;

        this.kazeiKubunAndZeiritsuElement.addEventListener("change", event => this.handleChange(event));
    }

    private async handleChange(event: Event) {

        if (event.target instanceof HTMLSelectElement) {
            if (event.target.value === "HIKAZEI") {
                let kazeiKubunElement = ObjectUtils.require(this.parentElement.querySelector("#kazeiKubun"), HTMLSelectElement);
                let zeiritsuElement = ObjectUtils.require(this.parentElement.querySelector("#zeiritsu"), HTMLSelectElement);

                kazeiKubunElement.value = "HIKAZEI";
                zeiritsuElement.value = "HIKAZEI";
            }
        }

        this.parentElement.querySelectorAll("#mitsumorishoMeisaiIndex,#mitsumorigakuTo").forEach((reloadTargetElement) => {
            reloadTargetElement.dispatchEvent(new CustomEvent("reload"));
        })

    }
}

export default KazeiKubunAndZeiritsuElement;